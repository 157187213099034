import React from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import raw from 'raw.macro';

import Main from '../layouts/Main';

// uses babel to load contents of file
const markdown = raw('../data/about.md');

// Make all hrefs react router links
export const LinkRenderer = ({ ...children }) => <a target="_blank" rel="noopener noreferrer" href={children.href}>{children.children}</a>;

const About = () => (
  <Main
    title="About"
    description="Learn about Cody Taylor"
  >
    <article className="post markdown" id="about">
      <header>
        <div className="title">
          <h2 data-testid="heading"><Link to="/about">About Me</Link></h2>
        </div>
      </header>
      <ReactMarkdown
        renderers={{
          link: LinkRenderer,
        }}
        allowDangerousHtml={false}
      >
        {markdown}
      </ReactMarkdown>
    </article>
  </Main>
);

export default About;
