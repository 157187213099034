import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const { NODE_ENV } = process.env;

const Analytics = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (NODE_ENV === 'production') {
      ReactGA.set({ page: pathname });
      ReactGA.send({ hitType: 'pageview', page: pathname });
    }
  }, [pathname]);

  return null;
};

export default React.memo(Analytics);
