const degrees = [
  {
    school: 'Western Governors University',
    degree: 'B.S. Business Administration, Information Technology Management',
    link: 'https://www.wgu.edu/online-business-degrees/it-information-technology-management-bachelors-program.html',
    year: 2021,
  },
  {
    school: 'DigitalCrafts',
    degree: 'Full Stack Software Development',
    link: 'https://www.digitalcrafts.com/',
    year: 2017,
  },
];

export default degrees;
