const positions = [
  {
    company: 'Carta',
    position: 'Senior Software Engineer II',
    link: 'https://carta.com',
    daterange: 'December 2021 - Present',
    points: [
      'Technical lead for a team of Software Engineers, ensuring alignment with product and design for high-quality customer releases. Developed customer-facing enterprise tools for equity administration and management.',
      'Led the development of a new feature within the Carta Exercise workflow, enabling users to seamlessly and automatically file their 83(b) forms with the IRS. Prior to this feature, users had to undertake a manual and time-consuming process of printing, filling out forms, and mailing them to the IRS. Achieved a remarkable adoption rate, with approximately 88% of eligible exercises utilizing the feature, greatly simplifying the submission process for Carta\'s users.',
      'Played a pivotal role in the Issuance Revamp project. This project included enhancements to existing features and new features to make the issuance process more user-friendly and reduce support tickets. The average time to issue new securities went from 4.5 days to less than 24 hours and positive feedback for Issuance went from 65% to 81%.',
    ],
  },
  {
    company: 'EOG Resources',
    position: 'Senior Software Engineer',
    link: 'https://www.eogresources.com',
    daterange: 'July 2017 - December 2021',
    points: [
      'Served as a Senior Software Engineer and lead engineer for the GIS/Maps team at EOG Resources, overseeing the development of applications, packages, APIs, and ETL processes. Provided mentorship to junior engineers and was a founding member of the EOG React Leadership team.',
      'Led the Energy Transition Platform Digital Twin initiative, reporting directly to the SVP and Chief Information and Technology Officer. This initiative aimed to reduce EOG’s net emissions by offering valuable insights for emissions forecasting and decision-making, significantly impacting the company\'s bottom line.',
      'Acted as the Technical Project Lead for the premier multi-dimensional mapping application and packages, playing a pivotal role in delivering innovative geospatial solutions, which achieved an impressive 90% adoption rate among teams at EOG Resources, showcasing the practicality and value of the solutions.',
      'Build proprietary services, applications, REST API’s, and Linux server administration.',
    ],
  },
  {
    company: 'Same Day Shipping Company',
    position: 'Software Engineer',
    // link: 'http://skepticalinvestments.biz',
    daterange: 'February 2017 - July 2017',
    points: [
      'Created web-based applications that automate daily tasks to streamline workflow for office and warehouse operations.',
      'Built programs that scrape data from multiple sources to build reports and store data regarding sales, inventory, customer feedback, auto-pricing, and competitor statistics.',
    ],
  },
];

export default positions;
