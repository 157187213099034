import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './static/css/main.scss';
import About from './pages/About';
import Contact from './pages/Contact';
import Index from './pages/Index';
import NotFound from './pages/NotFound';
import Resume from './pages/Resume';

const { PUBLIC_URL } = process.env;

const App = () => (
  <BrowserRouter basename={PUBLIC_URL}>
    <Switch>
      <Route exact path="/" component={Index} />
      <Route path="/about" component={About} />
      {/* <Route path="/projects" component={Projects} /> */}
      {/* <Route path="/stats" component={Stats} /> */}
      <Route path="/contact" component={Contact} />
      <Route path="/resume" component={Resume} />
      <Route component={NotFound} status={404} />
    </Switch>
  </BrowserRouter>
);

export default App;
